








































































import { defineComponent, PropType } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  getCacheKeyFromProps,
  scrollToFirstError,
  checkPassword,
} from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfOrderRegister',
  mixins: [validationMixin],
  serverCacheKey: getCacheKeyFromProps,
  props: {
    mainContent: {
      type: String as PropType<string>,
      default: '',
    },
    isButtonDisabled: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    translations: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    password: null,
    isTermsConfirmed: false,
    isLoyaltyProgramConfirmed: false,
  }),
  computed: {
    isFormInvalid() {
      return this.$v.$invalid;
    },
  },
  validations() {
    return {
      password: {
        required,
        checkPassword,
      },
    };
  },
  methods: {
    async registerAccount() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        await this.$nextTick();
        scrollToFirstError();
        return;
      }

      this.$emit('register-account', {
        password: this.password,
      });
    },
  },
});
