






























































import {
  defineComponent,
  computed,
  ref,
  watch,
  inject,
} from '@vue/composition-api';
import debounce from '@vf/shared/src/utils/helpers/debounce';

export default defineComponent({
  name: 'VfQuantitySelector',
  props: {
    disabled: Boolean,
    value: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 10,
    },
    ariaLabel: String,
    maxAllowed: {
      type: Number,
      default: 10,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    // TODO: GLOBAL15-61059 remove after core redesign
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const maxQuantity = computed(() => Math.min(props.max, props.maxAllowed));
    const isMinimumReached = computed(() => props.value <= props.min);
    const isMaximumReached = computed(() => props.value >= maxQuantity.value);

    const inputValue = ref(props.value);
    const previousValue = ref(props.value);

    const errorMessageText = ref(
      props.errorMessage.replace('{quantity}', String(maxQuantity.value))
    );
    const isErrorMessageVisible = computed(
      () => props.errorMessage && isMaximumReached.value
    );

    const changeValueHandler = (
      value: number | string,
      type?: string
    ): number => {
      if (props.disabled) return;

      let valueChanged = true;
      let v = value === '' ? props.min : Number(value);
      const minus = type === 'isMinus';
      const plus = type === 'isPlus';

      if (v > props.min && v < maxQuantity.value) {
        // If the value is in the maximum and minimum range, change it
        v = minus ? v - 1 : plus ? v + 1 : v;
      } else if (v < props.min) {
        // If the value is less than the minimum emit 'reset' event to remove product from the cart
        valueChanged = false;
        context.emit('reset');
        v = props.min;
      } else if (v > maxQuantity.value) {
        // If the value is greater than the maximum set it to maximum
        v = maxQuantity.value;
      } else if (v === props.min) {
        // If the value is minimum change it if plus was clicked or leave if value was changed manually
        v = plus ? v + 1 : props.min;
      } else if (v === maxQuantity.value) {
        // If the value is maximum change it if minus was clicked or leave if value was changed manually
        v = minus ? v - 1 : maxQuantity.value;
      }

      // Overwrite value provided by the user if it is invalid
      if (+v !== +inputValue.value) {
        inputValue.value = v;
      }

      if (valueChanged) {
        context.emit('change', {
          value: Number(v),
          onFinishCall: (isSuccess: boolean) => {
            if (isSuccess) {
              if (type) {
                previousValue.value = Number(v);
              }
            } else {
              inputValue.value = previousValue.value;
            }
          },
        });
      }

      return Number(v);
    };

    const handleChange = debounce((e: Event) => {
      const target = e.target as HTMLInputElement;
      const value = +target.value;

      if (value !== inputValue.value && target.value !== '') {
        previousValue.value = props.value || props.min;
        target.value = String(Math.min(maxQuantity.value, Math.max(0, value)));
      }

      if (e.type == 'blur') {
        if (target.value == '') {
          target.value = '1';
        }
      }

      changeValueHandler(target.value);
    }, 300);

    watch(
      () => props.value,
      (newVal) => (inputValue.value = newVal)
    );

    return {
      isCoreRedesignEnabled,
      maxQuantity,
      isMinimumReached,
      isMaximumReached,
      inputValue,
      errorMessageText,
      isErrorMessageVisible,
      changeValueHandler,
      handleChange,
    };
  },
});
