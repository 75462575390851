







import {
  computed,
  ComputedRef,
  watch,
  onBeforeUnmount,
  defineComponent,
} from '@vue/composition-api';
import { useSearch, useGtm, getRestoredSearchSettings } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { getFinalPdoSearch } from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { getPageCanonicalUrl } from '@vf/composables/src/useCms/utils/getPageCanonicalUrl';
import { getExplorePageLocalizations } from '@vf/composables/src/useCms/utils/getExplorePageLocalizations';

export default defineComponent({
  layout: 'cms/cmsDefault',
  props: {
    contextKey: String,
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);
    const {
      getSearchResults,
      setQueryString,
      setRequestType,
      setActivePage,
      clearPdoSearch,
      pagination,
      queryString,
      autoCorrectQuery,
      isRedirected,
      products,
      pdoSearch,
      meta,
      headerData,
    } = useSearch(root);

    const { pageTypeName } = storeToRefs(cmsRefStore);

    const pageUrl = root.$route.fullPath;
    const routeName = root.$route.name;
    const thematicPages =
      root.$getEnvValueByCurrentLocale<{ thematicPages: boolean }>(
        'BLOOMREACH_LOCALE_CONFIG'
      )?.thematicPages === true;

    const queryTerm: ComputedRef<string> = computed(() =>
      root.$route.params.id?.replace(/-/g, ' ')
    );

    const setSearchValues = () => {
      setQueryString(queryTerm.value);
      setActivePage(1);
      thematicPages && setRequestType('thematic');
    };

    const getCanonicalLinks = () => {
      let res = [];
      const canonical =
        headerData.value.canonicalURL ||
        getPageCanonicalUrl(root.$route.path, root);
      res.push({ rel: 'canonical', href: canonical });
      const localizations = getExplorePageLocalizations(
        canonical,
        cmsRefStore.page.localizations,
        root.$i18n.locale
      );
      localizations.forEach((loc) =>
        res.push({
          rel: 'alternate',
          href: `${loc.value.urlHost}${loc.value.urlSegment}`,
          hreflang: loc.key,
        })
      );
      return res;
    };

    setSearchValues();

    if (!thematicPages) {
      watch(
        queryTerm,
        () => {
          setSearchValues();
        },
        { immediate: true }
      );
      // Immediately calling getSearchResults causes a race condition
      // with the apiDateFetchMethod in the CmsPage component
      watch(queryTerm, async () => {
        await getSearchResults();
        dispatchEvent({
          eventName: 'loadPageData',
          overrideAttributes: {
            pageTypeName: pageTypeName.value ?? PageTypeName.SEARCH,
            categoryID: root.$route.params.id ?? undefined,
            ...getFinalPdoSearch(
              pdoSearch.value,
              pageTypeName.value ?? PageTypeName.SEARCH,
              queryString.value,
              autoCorrectQuery.value,
              isRedirected.value,
              pagination.value.total
            ),
          },
        });
        clearPdoSearch();
        dispatchEvent({ eventName: 'loadUserData' });
        const productIdList = products.value.map((p) => p.id);
        if (productIdList.length) {
          dispatchEvent({
            eventName: 'productImpressions',
            composablesContexts: { useSearch: props.contextKey },
            overrideAttributes: { productIds: productIdList },
            persistentVariables: { searchTerm: queryString.value },
          });
        }

        dispatchEvent({
          eventName: 'virtualPageView',
          overrideAttributes: {
            pageUrl,
            routeName,
          },
        });
      });
    } else {
      onBeforeUnmount(() => setRequestType(null));
    }

    return {
      path: root.$route.path.replace(`/${root.$route.params.id}`, ''),
      getSearchResults,
      searchSettings: getRestoredSearchSettings(),
      meta,
      exploreCanonicalLinks: computed(
        () => headerData.value && getCanonicalLinks()
      ),
      thematicPages,
    };
  },
  head() {
    return this.thematicPages
      ? {
          meta: [
            {
              hid: 'title',
              name: 'title',
              property: 'title',
              content: this.meta?.title?.content,
            },
            {
              hid: 'description',
              name: 'description',
              property: 'description',
              content: this.meta?.description?.content,
            },
            {
              hid: 'og:title',
              name: 'og:title',
              property: 'og:title',
              content: this.meta['og:title']?.content,
            },
            {
              hid: 'og:description',
              name: 'og:description',
              property: 'og:description',
              content: this.meta['og:description']?.content,
            },
            {
              name: 'keywords',
              content: this.meta.keywords?.content,
            },
          ],
          link: this.exploreCanonicalLinks || [],
        }
      : {};
  },
});
