//
//
//
//
//
//
//

import { getRestoredSearchSettings, useSearch, useGtm } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { getFinalPdoSearch } from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { computed, watch } from '@vue/composition-api';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { getPageCanonicalUrl } from '@vf/composables/src/useCms/utils/getPageCanonicalUrl';
import { getExplorePageLocalizations } from '@vf/composables/src/useCms/utils/getExplorePageLocalizations';

export default {
  layout: 'cms/cmsDefault',
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);
    const {
      getSearchResults,
      setActivePage,
      setQueryString,
      pagination,
      queryString,
      autoCorrectQuery,
      isRedirected,
      products,
      pdoSearch,
      clearPdoSearch,
      meta,
      headerData,
    } = useSearch(root);

    const { pageTypeName } = storeToRefs(cmsRefStore);

    const pathClear = root.$route.path.slice(
      0,
      root.$route.path.lastIndexOf('/')
    );
    const queryTag = computed(() => root.$route.params.id);
    const pageUrl = root.$route.fullPath;
    const routeName = root.$route.name;

    const setSearchValues = () => {
      setQueryString(queryTag.value);
      setActivePage(1);
    };

    setSearchValues();

    watch(
      queryTag,
      () => {
        setSearchValues();
      },
      { immediate: true }
    );

    // Immediately calling getSearchResults causes a race condition
    // with the apiDateFetchMethod in the CmsPage component
    watch(queryTag, async () => {
      await getSearchResults();
      dispatchEvent({
        eventName: 'loadPageData',
        overrideAttributes: {
          pageTypeName: pageTypeName.value || PageTypeName.SEARCH,
          categoryID: root.$route.params.id || undefined,
          ...getFinalPdoSearch(
            pdoSearch.value,
            pageTypeName.value || PageTypeName.SEARCH,
            queryString.value,
            autoCorrectQuery.value,
            isRedirected.value,
            pagination.value.total
          ),
        },
      });
      clearPdoSearch();
      dispatchEvent({ eventName: 'loadUserData' });
      const productIdList = products.value.map((p) => p.id);
      if (productIdList.length) {
        dispatchEvent({
          eventName: 'productImpressions',
          composablesContexts: { useSearch: props.contextKey },
          overrideAttributes: { productIds: productIdList },
          persistentVariables: { searchTerm: queryString.value },
        });
      }

      dispatchEvent({
        eventName: 'virtualPageView',
        overrideAttributes: {
          pageUrl,
          routeName,
        },
      });
    });

    const getCanonicalLinks = () => {
      let links = [];

      const canonicalTag =
        headerData.value.canonicalURL ||
        getPageCanonicalUrl(root.$route.path, root);
      links.push({ rel: 'canonical', href: canonicalTag });

      const tagLocalizations = getExplorePageLocalizations(
        canonicalTag,
        cmsRefStore.page.localizations,
        root.$i18n.locale
      );
      tagLocalizations.forEach((tagLocalization) =>
        links.push({
          rel: 'alternate',
          href: `${tagLocalization.value.urlHost}${tagLocalization.value.urlSegment}`,
          hreflang: tagLocalization.key,
        })
      );

      return links;
    };

    const getTagMetas = () => {
      const metaKeysToSet = [
        'title',
        'description',
        'og:title',
        'og:description',
        'keywords',
      ];
      const metaValues = [];
      metaKeysToSet.forEach((key) => {
        if (meta.value[key]) {
          metaValues.push({
            hid: key !== 'keywords' ? key : undefined,
            name: key,
            property: key !== 'keywords' ? key : undefined,
            content: meta.value[key].content,
          });
        }
      });
      return metaValues;
    };

    return {
      getSearchResults,
      searchSettings: getRestoredSearchSettings(),
      pathClear,
      tagMetas: computed(() => meta.value && getTagMetas()),
      tagCanonicalLinks: computed(
        () => headerData.value && getCanonicalLinks()
      ),
    };
  },
  head() {
    return {
      meta: this.tagMetas,
      link: this.tagCanonicalLinks || [],
    };
  },
};
