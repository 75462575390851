import type { ComponentInstance } from '../types';
import { Ref, ref } from '@nuxtjs/composition-api';
import * as api from '@vf/api-client';
import { useFeatureFlagsStore } from '../store/featureFlags';
import { useI18n } from '../useI18n';
import type { AxiosResponse } from 'axios';

import type { ApiClient } from '@vf/api-client/src/getCmsResource/types';

import type {
  CmsCategoryPageResponse,
  CmsSiteConfigurationResponse,
} from '@vf/api-contract/src/cms';

import type {
  EndpointResponsePromise,
  EndpointHealthResult,
  EapiEndpointHealth,
} from './types';

import { EndpointType, Status } from './types';

import {
  prepareCount,
  prepareCategoryFilters,
  prepareFiltersJson,
  prepareSearch,
  prepareSort,
  prepareStart,
  prepareLocale,
} from '../utils/query';

const SETTINGS = {
  start: 0,
  per: 24,
  sort: 'bestMatches',
  filters: [],
};

const THRESHOLD_TIMEOUT = 5 * 1000; // time in ms
const THRESHOLD_SLOW = 1.5 * 1000; // time ms

export const useHealthcheck = (instance: ComponentInstance) => {
  const {
    getCatalog: getCatalogAPI,
    getProductDetails: getProductDetailsAPI,
    getProductInventory: getProductInventoryAPI,
    getSearchResults: getSearchResultsAPI,
  } = api.apiClientFactory(instance);
  const { localeCode } = useI18n(instance);

  const getDefaultQuery = () => [
    prepareStart(SETTINGS.start),
    prepareCount(SETTINGS.per),
    prepareSort(SETTINGS.sort),
    prepareLocale(localeCode()),
  ];

  const { configureHealthCheckPageSettings } = useFeatureFlagsStore();
  const categoryId = configureHealthCheckPageSettings.categoryId || '';
  const productId = configureHealthCheckPageSettings.productId || '';
  const searchQuery = configureHealthCheckPageSettings.searchQuery || '';

  const measurePromise = async (
    name: string,
    promise: EndpointResponsePromise
  ): Promise<{
    error: boolean;
    time: number;
    statusMessage: string;
    response?: AxiosResponse | unknown;
  }> => {
    const start = Date.now();

    try {
      const response = await promise;
      const time = Date.now() - start;
      return {
        error: false,
        time: time,
        statusMessage: ``,
        response,
      };
    } catch (err) {
      instance.$log.error(
        `[@useHealthcheck::measurePromise] ${name} failed: ${err.message}`,
        { err }
      );
      return {
        error: true,
        time: 0,
        statusMessage: `Error code: ${err.code} | Error message: ${err.message}`,
        response: err,
      };
    }
  };

  const handleTimeout = (
    name: string,
    resolved: Ref<boolean>
  ): Promise<{ error: boolean; time: number; statusMessage: string }> =>
    new Promise((resolve) => {
      const id = setTimeout(() => {
        clearTimeout(id);
        if (!resolved.value) {
          const statusMessage = `API request ${name} timed out after ${THRESHOLD_TIMEOUT} ms.`;
          instance.$log.error(
            `[@useHealthcheck::getEndpointHealth] ${statusMessage}`
          );
          resolve({
            error: true,
            time: THRESHOLD_TIMEOUT,
            statusMessage: statusMessage,
          });
        }
      }, THRESHOLD_TIMEOUT);
    });

  const getEndpointStatus = (result: EndpointHealthResult): Status => {
    if (result.error) {
      return Status.ERROR;
    }

    if (result.time >= THRESHOLD_SLOW) {
      return Status.WARNING;
    }
    return Status.SUCCESS;
  };

  const handleEndpointHealthResult = (
    type: EndpointType,
    result: EndpointHealthResult
  ) => {
    if (!result) return;

    if (type === EndpointType.EAPI) {
      return {
        status: result.response.status,
        headers: result.response.headers,
        data: result.response.data,
      };
    } else {
      return {
        data: (result.response as unknown) as
          | CmsSiteConfigurationResponse
          | CmsCategoryPageResponse,
      };
    }
  };

  const getEndpointHealth = async (
    type: EndpointType,
    name: string,
    path: string,
    promise: EndpointResponsePromise
  ): Promise<EapiEndpointHealth> => {
    const resolved: Ref<boolean> = ref(false);
    try {
      const result: any = await Promise.race([
        measurePromise(name, promise),
        handleTimeout(name, resolved),
      ]);

      resolved.value = true;
      return {
        status: getEndpointStatus(result),
        statusMessage: !result.error
          ? result.statusMessage
          : JSON.stringify(result),
        apiName: name,
        apiPath: path,
        response: handleEndpointHealthResult(type, result),
        responseTime: result?.time,
      };
    } catch (err) {
      resolved.value = true;
      return {
        status: Status.ERROR,
        statusMessage: err,
        apiName: name,
        apiPath: path,
        response: undefined,
        responseTime: 0,
      };
    }
  };

  const getCatalog = async () => {
    if (!categoryId) {
      throw new Error(
        '[@useHealthCheck::getCatalog] <categoryId> not set in healthcheckSettings flag in Launch Darkly'
      );
    }
    const query = getDefaultQuery();

    query.push(prepareCategoryFilters(SETTINGS.filters, categoryId, true));

    query.push(prepareFiltersJson(SETTINGS.filters, categoryId));

    return getCatalogAPI(query.join('&'));
  };

  const getProductDetails = async () => {
    if (!productId) {
      throw new Error(
        '[@useHealthCheck::getProduct] <productId> not set in healthcheckSettings flag in Launch Darkly'
      );
    }

    const query: string[] = [prepareLocale(localeCode())];

    return getProductDetailsAPI(productId, query.join('&'));
  };

  const getProductInventory = async () => {
    if (!productId) {
      throw new Error(
        '[@useHealthCheck::getProductInventory] <productId> not set in healthcheckSettings flag in Launch Darkly'
      );
    }

    const query: string[] = [prepareLocale(localeCode())];

    return getProductInventoryAPI(productId, query.join('&'));
  };

  const getSearchResults = async () => {
    if (!searchQuery) {
      throw new Error(
        '[@useHealthCheck::getSearchResults] <searchQuery> not set in healthcheckSettings flag in Launch Darkly'
      );
    }
    const query = getDefaultQuery();
    query.push(prepareSearch(encodeURIComponent(searchQuery)));

    return getSearchResultsAPI(query.filter((i) => Boolean(i)).join('&'));
  };

  const getEapiHealth = async (
    renderer: string
  ): Promise<EapiEndpointHealth[]> => {
    const eapiHealth = [];

    await Promise.allSettled([
      (async () =>
        eapiHealth.push(
          await getEndpointHealth(
            EndpointType.EAPI,
            `GET CATEGORY <${categoryId}>`,
            '/products/v1/catalog',
            getCatalog()
          )
        ))(),
      (async () =>
        eapiHealth.push(
          await getEndpointHealth(
            EndpointType.EAPI,
            `GET PRODUCT DETAILS <${productId}>`,
            `/products/v1/products/${productId}/details`,
            getProductDetails()
          )
        ))(),
      (async () =>
        eapiHealth.push(
          await getEndpointHealth(
            EndpointType.EAPI,
            `GET SEARCH PRODUCTS <${searchQuery}>`,
            '/products/v1/search/productSearch',
            getSearchResults()
          )
        ))(),
      ['CSR'].includes(renderer)
        ? (async () =>
            eapiHealth.push(
              await getEndpointHealth(
                EndpointType.EAPI,
                `GET PRODUCT INVENTORY <${productId}>`,
                `/products/v2/products/${productId}/inventory`,
                getProductInventory()
              )
            ))()
        : null,
    ]);

    return eapiHealth.sort((a, b) => a.apiName.localeCompare(b.apiName));
  };

  const getCmsHealth = async (renderer: string) => {
    const cmsHealth = [];
    let cmsApiClient: ApiClient;
    try {
      const envProvider =
        instance.$env.NODE_ENV === 'development' ? instance.$env : process.env;

      cmsApiClient = await api.cmsApiClient({
        baseUri: instance.$env[`COREMEDIA_URI_${renderer}`],
        baseMediaUri: instance.$env[`COREMEDIA_URI_CSR`],
        menuEndpoint: instance.$env['COREMEDIA_MENU_ENDPOINT'],
        headers: {
          [envProvider[`COREMEDIA_ACCESS_HEADER_${renderer}`]]:
            envProvider[`COREMEDIA_ACCESS_TOKEN_${renderer}`],
        },
      });
    } catch (err) {
      instance.$log.error(
        `[@useHealthcheck::getCmsHealth] Error when initializing cmsApiClient with renderer <${renderer}>. Error: ${err.message}`,
        { err }
      );
      return cmsHealth;
    }

    const siteId = instance.$getEnvValueByCurrentLocale<string>('CMS_SITE');

    const cmsSiteConf = await getEndpointHealth(
      EndpointType.CMS,
      `GET SITE CONFIGURATION <${siteId}>`,
      'content/publish/caas/v1/site/',
      cmsApiClient.getSiteConfiguration(siteId)
    );

    cmsHealth.push(cmsSiteConf);

    const cmsRootId = ((cmsSiteConf.response
      .data as unknown) as CmsSiteConfigurationResponse).site.root.id;

    await Promise.allSettled([
      productId
        ? (async () =>
            cmsHealth.push(
              await getEndpointHealth(
                EndpointType.CMS,
                `GET PDP CONTENT <${productId}>`,
                `/content/publish/caas/v1/commercepage/${siteId}/product/${productId}`,
                cmsApiClient.getProductPageContent(siteId, productId)
              )
            ))()
        : null,
      categoryId
        ? (async () =>
            cmsHealth.push(
              await getEndpointHealth(
                EndpointType.CMS,
                `GET PLP CONTENT <${categoryId}>`,
                `/content/publish/caas/v1/commercepage/${siteId}/category/${categoryId}`,
                cmsApiClient.getCategoryPageContent(siteId, categoryId)
              )
            ))()
        : null,
      (async () =>
        cmsHealth.push(
          await getEndpointHealth(
            EndpointType.CMS,
            `GET HOMEPAGE`,
            `/content/publish/caas/v1/pagecontent/${cmsRootId}`,
            cmsApiClient.getPageContent(cmsRootId)
          )
        ))(),
      (async () =>
        cmsHealth.push(
          await getEndpointHealth(
            EndpointType.CMS,
            `GET HEADER AND FOOTER`,
            `content/publish/caas/v1/menu/${cmsRootId}`,
            cmsApiClient.getHeaderAndFooter(cmsRootId)
          )
        ))(),
    ]);

    return cmsHealth.sort((a, b) => a.apiName.localeCompare(b.apiName));
  };

  return {
    getEapiHealth,
    getCmsHealth,
    THRESHOLD_SLOW,
    THRESHOLD_TIMEOUT,
  };
};

export default useHealthcheck;
