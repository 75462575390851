

















import { computed, defineComponent, PropType } from '@vue/composition-api';
import { ShippingMethods } from '@vf/api/src/types/Checkout';
import { LocaleDataObject } from '@vf/api-contract';
import {
  getCacheKeyFromProps,
  getDeliveryDateLabel,
} from '@vf/shared/src/utils/helpers';

interface OrderShipmentLabelTranslations {
  /** Narvar EDD delivery date text */
  deliveryDateLabel: string;
  /** When Shipping Price is zero */
  free: string;
}

export default defineComponent({
  name: 'VfOrderShipmentLabel',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<OrderShipmentLabelTranslations>,
      required: true,
    },
    textContent: {
      type: String,
      default: '',
    },
    shippingMethod: {
      type: Object as PropType<ShippingMethods>,
      default: () => ({
        label: '',
        price: {
          amount: 0,
          currency: '',
        },
        deliveryTime: '',
        shippingDuration: '',
      }),
    },
    localeData: {
      type: Object as PropType<LocaleDataObject>,
      default: () => ({
        localeCode: '',
        localeMapping: '',
      }),
    },
  },
  setup(props, { root }) {
    const calculateMethodPrice = (method: ShippingMethods) => {
      if (!method.price?.amount) {
        return props.translations.free || 'Free';
      }
      return root.$formatPrice(method.price.amount, method.price.currency);
    };

    return {
      label: computed(() => props.shippingMethod.label),
      shippingTime: computed(() =>
        getDeliveryDateLabel(
          props.shippingMethod.deliveryTime ||
            props.shippingMethod.shippingDuration ||
            '',
          props.translations.deliveryDateLabel,
          false,
          true,
          props.localeData
        )
      ),
      price: computed(() => calculateMethodPrice(props.shippingMethod)),
    };
  },
});
