//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfContentPage',
  inject: ['provided'],
  props: {
    /**
     * Page ID
     */
    pageId: {
      type: Number,
      default: 0,
    },
    /**
     * Page nav title
     */
    icon: {
      type: [String, Array],
      default: '',
    },
  },
  computed: {
    isActive() {
      return this.provided.active === this.pageId;
    },
  },
});
