




























import { defineComponent } from '@nuxtjs/composition-api';
import FeatureFlagsStatus from '../../components/healthcheck/FeatureFlagsStatus.vue';
import HealthCheck from '../../components/healthcheck/HealthCheck.vue';
import I18nTranslations from '../../components/healthcheck/I18nTranslations.vue';

const THRESHOLD_TIMEOUT = 5 * 1000; // time in ms
const THRESHOLD_SLOW = 1.5 * 1000; // time ms

export default defineComponent({
  components: {
    FeatureFlagsStatus,
    HealthCheck,
    I18nTranslations,
  },
  layout: 'empty',
  setup() {
    return {
      THRESHOLD_SLOW,
      THRESHOLD_TIMEOUT,
    };
  },
});
