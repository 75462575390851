import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { GetListingResultsResponse } from '@vf/api-client/src/api-types';
import {
  CmsCategoryPageResponse,
  CmsSiteConfigurationResponse,
  CmsSitesConfigurationResponse,
  CmsMenuResponse,
} from '@vf/api-contract/src/cms';

export type EndpointResponsePromise = Promise<
  | CmsSitesConfigurationResponse
  | CmsSiteConfigurationResponse
  | CmsCategoryPageResponse
  | CmsMenuResponse
  | AxiosResponse<GetListingResultsResponse>
>;

export type EndpointHealthResult = {
  time: number;
  error: boolean;
  statusMessage: string;
  response: AxiosResponse;
};
export enum EndpointType {
  EAPI = 'EAPI',
  CMS = 'CMS',
}

export enum Status {
  SUCCESS = 'green',
  WARNING = 'orange',
  ERROR = 'red',
}

export type ServiceResponse = {
  headers?: AxiosResponseHeaders;
  data: any;
  status?: number;
};

export interface EapiEndpointHealth {
  status: Status;
  statusMessage: string;
  apiName: string;
  apiPath: string;
  response?: ServiceResponse;
  responseTime: number;
}
