




import { defineComponent } from '@vue/composition-api';
import { useAuthentication, ROUTES, useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  layout: 'cms/cmsDefault',
  setup() {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);

    if (root.$route.path !== localePath(ROUTES.SIGN_OUT())) {
      return;
    }

    const { signOut } = useAuthentication(root);
    signOut();
  },
});
