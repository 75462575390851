//
//
//
//
//
//
//
//
//

import vueLottie from 'vue-lottie/src/lottie.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfLottie',
  components: {
    vueLottie,
  },
  props: {
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      anim: null,
    };
  },
});
