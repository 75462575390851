//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfMenuItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: [String, Array],
      default: 'chevron_right',
    },
    count: {
      type: [String, Number],
      default: '',
    },
    labelStyles: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
});
