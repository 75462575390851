
























































































































































































































































import type { PropType } from 'vue';
import type { ThemeProductCardConfig } from '../types';

import { defineComponent, inject } from '@vue/composition-api';
import { CartLineItem } from '@vf/api-client';
import { replaceAll } from '@vf/theme/helpers/replaceAll';
import {
  isMainlineItem,
  getNonMainlineProductPdpUrl,
  removeDomain,
} from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfThemeProductCard',
  props: {
    product: {
      type: Object as PropType<CartLineItem>,
      required: true,
    },
    notifications: {
      type: Array as PropType<{ type?: string; message: string }[]>,
    },
    config: {
      type: Object as PropType<ThemeProductCardConfig>,
      default: () => ({
        imageWidth: 110,
        imageHeight: 139,
        removeIcon: 'close',
        removeIconSize: '14px',
      }),
    },
    /** Option to determine if product has a remove from cart icon rendered */
    renderRemoveIcon: Boolean,
    hideRemoveIcon: Boolean,
    /** Option to determine if ProductCard should be displayed in compressed state (used in order summary sidebar) */
    isCompact: { type: Boolean, default: false },
    /** Option to determine if product is saved for later */
    isSavedForLater: { type: Boolean, default: false },
    /** Option to determine if products is disabled when some action is in progress */
    isDisabled: { type: Boolean, default: false },
    /** Option to determine if products is OOS */
    isOutOfStock: { type: Boolean, default: false },
    /** Price value of gift box (visible only on order summary sidebar) */
    giftBoxPrice: { type: String, default: '' },
    /** Determines whether gift info is visible or not (hidden on mini cart) */
    hideGiftInfo: Boolean,
    /** Determines whether color, size, length should be visually hidden ir not */
    hideAttributesLabel: Boolean,
    /** Option to determine if product is editable */
    isEditable: { type: Boolean, default: true },
  },
  setup() {
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    const isCheckoutSuccessRedesignEnabled = inject(
      'isCheckoutSuccessRedesignEnabled'
    );
    return {
      isCoreRedesignEnabled,
      isCheckoutSuccessRedesignEnabled,
    };
  },
  data() {
    return {
      changeQuantityInProgress: false,
      lowStockThreshold: 10, //always 10
    };
  },
  computed: {
    productUrl() {
      if (!isMainlineItem(this.product)) {
        return getNonMainlineProductPdpUrl(this.product, this);
      }
      const relativeUrl = removeDomain(this.product.pdpUrl);
      return this.localePath(relativeUrl);
    },
    attributes() {
      const attrs = (this.product.variants ?? []).map(({ label, value }) => ({
        label,
        value,
      }));

      if (attrs && this.giftBoxPrice) {
        attrs.push({
          label: this.$t('productCard.giftBox'),
          value: this.giftBoxPrice,
        });
      }

      return attrs;
    },
    price() {
      const {
        currency,
        original,
        current,
        rowTotal,
        priceAfterOrderDiscount = rowTotal, // undefined for SFL
      } = this.product.price ?? {};
      const { qty = 1 } = this.product;
      const [regular, special] = [
        original * qty,
        current * qty,
        priceAfterOrderDiscount ?? current * qty,
      ]
        .map((num) => Math.round((num + Number.EPSILON) * 100) / 100) // handling for [59.849999999999994, 59.85]
        .filter((p, i, a) => a.indexOf(p) === i)
        .sort((a, b) => b - a)
        .slice(-2)
        .map((price) => this.$root.$formatPrice(price, currency));

      return {
        regular,
        special,
      };
    },
    saveForLaterState() {
      return this.isSavedForLater
        ? {
            eventName: 'click:move-to-cart',
            icon: 'cart',
            text: this.$t('productCard.moveToCart'),
          }
        : {
            eventName: 'click:save-for-later',
            icon: 'arrow_down',
            text: this.$t('productCard.saveForLater'),
          };
    },
    maxQuantity() {
      // Filters and exclude NaN values (undefined) and returns the lowest value from possible limits for maximum quantity
      return Math.min(
        ...[this.product.maxAllowedQty, this.product.maxQty].filter(
          (number) => !isNaN(number)
        )
      );
    },
    isQuantityPickerDisabled() {
      return (
        this.changeQuantityInProgress || this.isOutOfStock || this.product.bonus
      );
    },
  },
  methods: {
    changeQuantity({ value, onFinishCall }) {
      this.changeQuantityInProgress = true;
      this.$emit('click:change-quantity', {
        product: this.product,
        quantity: parseInt(value),
        onFinishCall: (status) => {
          this.changeQuantityInProgress = false;
          onFinishCall(status);
        },
      });
    },
    replaceAll,
    isMainlineItem,
  },
});
