



































import { defineComponent, ref } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'FeatureFlagsStatus',
  layout: 'empty',
  props: {},
  setup() {
    const { root } = useRootInstance();
    const featureFlagsStore = useFeatureFlagsStore();
    const parsedFeatureFlags = ref([]);

    const storedFeatureFlags = Object.entries({ ...featureFlagsStore.$state });
    storedFeatureFlags.forEach((ff) => {
      parsedFeatureFlags.value.push({
        key: ff[0],
        type: typeof ff[1],
        value: ff[1],
      });
    });

    return {
      isLaunchDarklyEnabled: !!root.$env.LAUNCHDARKLY_CLIENT_KEY,
      parsedFeatureFlags,
    };
  },
});
