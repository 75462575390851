




import { defineComponent } from '@vue/composition-api';
import { useReturns } from '@vf/composables';
import { ReturnStepsType } from '@vf/composables/src/useReturns';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  layout: 'cms/cmsDefault',
  setup() {
    const { root } = useRootInstance();
    const { setReturnStep } = useReturns(root);
    setReturnStep(ReturnStepsType.EMPTY);

    return {
      path: root.$route.path.replace(`/${root.$route.params.id}`, ''),
    };
  },
});
