













































































































































































































































































































import type { PropType } from 'vue';
import type { OrderProductsSummaryTranslations } from '@vf/api-contract';

import { computed, defineComponent } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfOrderProduct',
  serverCacheKey: getCacheKeyFromProps,
  mixins: [validationMixin],
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    translations: {
      type: Object as PropType<OrderProductsSummaryTranslations>,
      required: true,
    },
    overrideImage: {
      type: String,
      default: null,
    },
    imageHeight: {
      type: Number,
      default: 140,
    },
    imageWidth: {
      type: Number,
      default: 140,
    },
    showGiftOption: {
      type: Boolean,
      default: false,
    },
    showItemPrice: {
      type: Boolean,
      default: false,
    },
    showSmallDescription: {
      type: Boolean,
      default: true,
    },
    showOnlyColorValue: {
      type: Boolean,
      default: false,
    },
    showReturnsActions: {
      type: Boolean,
      default: false,
    },
    isReturned: {
      type: Boolean,
      default: false,
    },
    isCancelled: {
      type: Boolean,
      default: false,
    },
    returnStep: {
      type: String,
      default: '',
    },
    returnReason: {
      type: String,
      default: '',
    },
    isNotificationTextVisible: {
      type: Boolean,
      default: false,
    },
    isPricingStrikethroughEnabled: Boolean,
  },
  setup(props, { root }) {
    const theme = root.$themeConfig.orderProduct || {};
    const themeImageWidth = theme.themeImageWidth ?? props.imageWidth;
    const themeImageHeight = theme.themeImageHeight ?? props.imageHeight;

    const giftOptionInProduct = computed<boolean>(
      () => props.showGiftOption && !!props.product.gift
    );

    const price = computed(() => {
      const {
        price: { currency, original, priceAfterItemDiscount },
        quantity,
      } = props.product;
      let qty = quantity.ordered;
      if (props.isCancelled) qty = quantity.cancelled;

      let unitPrice = root.$formatPrice(original, currency);
      let salePrice = root.$formatPrice(priceAfterItemDiscount / qty, currency);
      let totalPrice = root.$formatPrice(priceAfterItemDiscount, currency);

      // GLOBAL15-58107 - TODO: remove this condition when Narvar Returns will replace order returns
      if (
        props.returnStep === 'summary' ||
        props.returnStep === 'confirmation'
      ) {
        totalPrice = root.$formatPrice(
          (priceAfterItemDiscount / qty) * calculateQty(props.product),
          currency
        );
      }
      if (unitPrice === salePrice) {
        salePrice = null;
      }

      return {
        unitPrice,
        salePrice,
        totalPrice,
      };
    });

    const giftBoxPrice = computed<string>(() =>
      root.$formatPrice(
        props.product.giftBoxPrice,
        props.product.price?.currency
      )
    );
    const calculateQty = (product) => {
      if (props.showReturnsActions)
        return product.returnQty
          ? product.returnQty
          : product.quantity.returnable || product.quantity.ordered;
      if (props.isReturned) return product.orderedQty;
      if (props.isCancelled) return product.quantity.cancelled;
      if (product.shippingQuantity > 0) return product.shippingQuantity;
      return product.quantity.returnable || product.quantity.ordered;
    };

    const giftOptionsLabel = computed<string>(() => {
      if (!giftOptionInProduct.value)
        return props.translations.giftOptionsEmpty + '';
      const quantity = props.product.quantity.ordered;
      const text = props.product.isGiftBoxSelected
        ? props.translations.giftOptionsWithGiftBox
        : props.translations.giftOptionsWithoutGiftBox;
      return (text + '')
        .replace('{{quantity}}', quantity + '')
        .replace('{{price}}', giftBoxPrice.value);
    });

    const giftOptionBindings = computed(() => {
      const {
        gift,
        giftOption,
        isGiftBoxSelected,
        isGiftBoxAvailable,
      } = props.product;
      return {
        translations: props.translations,
        gift,
        giftBoxPrice: giftBoxPrice.value,
        isGiftBoxAvailable,
        isGiftBoxSelected,
        giftOption,
      };
    });

    const showReturnPrice = computed(
      // TODO: GLOBAL15-58107
      () => !props.showReturnsActions || props.returnStep === 'details'
    );

    return {
      price,
      showReturnPrice,
      themeImageWidth,
      themeImageHeight,
      giftOptionsLabel,
      giftOptionInProduct,
      giftOptionBindings,
      calculateQty,
    };
  },
  data() {
    return {
      collapsed: false,
      isSelected: false,
      reasonCode: '',
      returnQty: '1',
    };
  },
  validations: {
    reasonCode: {
      required,
    },
  },
  computed: {
    color() {
      return this.product.attributes?.find(
        (attr) => attr.code.toLowerCase() === 'color'
      );
    },
    size() {
      return this.product.attributes?.find(
        (attr) => attr.code.toLowerCase() === 'size'
      );
    },
    length() {
      return this.product.attributes?.find(
        (attr) => attr.code.toLowerCase() === 'length'
      );
    },
    getProductImage() {
      if (this.overrideImage) {
        return this.overrideImage;
      }
      return this.product.image;
    },
    itemHasPromoAppliedAndCalloutMsg() {
      return !!this.product.productPromotions?.some(
        (promo) => !!promo.calloutMsg?.length
      );
    },
    showReturnSelect() {
      return (
        this.showReturnsActions &&
        this.product.quantity.returnable &&
        this.product.isReturnable &&
        this.returnStep === 'details'
      );
    },
  },
  methods: {
    capitalizeText(value) {
      return value ? value.charAt(0).toUpperCase() + value.slice(1) : '';
    },
    getProductLink(product) {
      if (product.bonus) {
        return null;
      } else {
        return product.pdpUrl || product.link;
      }
    },
    getFormattedPrice(price, product) {
      return this.$root.$formatPrice(
        price,
        product.totals.currency
          ? product.totals.currency
          : product.price.currency
      );
    },
    handleUpdateReturn() {
      this.$emit('handle-update-return', {
        ...this.product,
        returnQty: this.returnQty,
        reasonCode: this.reasonCode,
        reasonText: this.translations.returnReasons[this.reasonCode],
        isInvalid: this.$v.$invalid,
        isSelected: this.isSelected,
      });
    },
  },
});
