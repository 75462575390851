var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vf-quantity-selector",class:{ 'vf-quantity-selector--disabled': _vm.disabled }},[_c('UiLink',{class:[
        'vf-quantity-selector__minus-button',
        { 'vf-quantity-selector__minus-button--disabled': _vm.isMinimumReached } ],attrs:{"aria-label":_vm.$t('quantitySelector.decreaseQuantity'),"disabled":_vm.disabled || _vm.isMinimumReached},on:{"click":function($event){return _vm.changeValueHandler(_vm.inputValue, 'isMinus')}}},[_c('VfIcon',{staticClass:"vf-quantity-selector__minus-button-icon",attrs:{"icon":"minus","size":"13px"}})],1),_vm._v(" "),_c('VfInput',{staticClass:"vf-quantity-selector__input",class:{
        'vf-quantity-selector__input--redesign-core': _vm.isCoreRedesignEnabled,
      },attrs:{"type":"number","label":"","min":_vm.min,"max":_vm.maxQuantity,"disabled":_vm.disabled,"validation-icons-visibility":false,"aria-label":_vm.ariaLabel || _vm.$t('quantitySelector.quantity')},on:{"change":_vm.handleChange},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),_vm._v(" "),_c('UiLink',{class:[
        'vf-quantity-selector__plus-button',
        {
          'vf-quantity-selector__plus-button--disabled': _vm.isMaximumReached,
        } ],attrs:{"aria-label":_vm.$t('quantitySelector.increaseQuantity'),"disabled":_vm.disabled || _vm.isMaximumReached},on:{"click":function($event){return _vm.changeValueHandler(_vm.value, 'isPlus')}}},[_c('VfIcon',{staticClass:"vf-quantity-selector__plus-button-icon",attrs:{"icon":"plus","size":"13px"}})],1)],1),_vm._v(" "),(_vm.isErrorMessageVisible)?_c('p',{staticClass:"vf-quantity-selector__error-message"},[_vm._v("\n    "+_vm._s(_vm.errorMessageText)+"\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }