//
//
//
//
//
//
//
//

export default {
  name: 'StoresStorePage',
  layout: 'cms/cmsDefault',
};
