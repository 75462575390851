









import type { PropType } from 'vue';
import { onMounted, onBeforeUnmount, computed } from '@vue/composition-api';
import type { MinicartTranslations } from '@vf/api-contract';
import { useGtm } from '@vf/composables';

export default {
  name: 'Customizer',
  beforeRouteEnter(to, from, next) {
    // only allow alphabetical letters, numbers, and dashes for style
    const regExp = new RegExp('^[a-zA-Z0-9-]+$');
    const allowedPath = regExp.test(to.params.pathMatch);
    allowedPath ? next() : next({ path: '/error' });
  },
  layout: 'cms/cmsDefault',
  props: {
    translations: {
      type: Object as PropType<MinicartTranslations>,
      default: () => ({
        qty: 'Quantity',
        title: 'Your Cart',
        totalItems: 'Total Items:',
        emptyCartInfo: 'There are no items in your cart',
        close: 'Close',
        remove: 'Remove',
        subtotal: 'Subtotal:',
        checkoutCTA: 'Checkout',
        shareMyCartCTA: 'Share my cart',
        viewFavorites: 'View Favorites',
        freeShippingInfo: 'Free shipping & returns',
        singleItem: 'item',
        multipleItems: 'items',
        promotions: {
          success: {
            title: "Yes! You've qualified",
            message: 'for Free Shipping!',
          },
          approaching: {
            title: "You're almost there",
            message: "You're {{remainingAmount}} away from Free Shipping!",
          },
        },
      }),
    },
  },
  setup(props, context) {
    const { root } = context;
    const { dispatchEvent } = useGtm(root);

    const currentLocale = root.$i18n.locale;
    const cmsPagePath = computed(() => {
      return `/${currentLocale}/customizer`;
    });
    onMounted(() => {
      root.$on('onDelete', checkOutFlyoutEvent);
      root.$on('openShareMyCartModal', checkOutFlyoutEvent);
      root.$on('navigateToCheckout', checkOutFlyoutEvent);
      root.$on('closeSidebar', closeSideBarEvent); // remove in GLOBAL15-56318
      root.$on('click:close-minicart', closeSideBarEvent);
    });

    const closeSideBarEvent = () => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventAction: 'Add to Cart Flyout',
          eventLabel: 'Close Modal',
        },
      });
    };
    const checkOutFlyoutEvent = (action) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Checkout Flyout',
          eventAction: action,
          eventLabel: '',
        },
      });
    };

    onBeforeUnmount(() => {
      root.$off('onDelete', checkOutFlyoutEvent);
      root.$off('openShareMyCartModal', checkOutFlyoutEvent);
      root.$off('navigateToCheckout', checkOutFlyoutEvent);
      root.$off('closeSidebar', closeSideBarEvent); // remove in GLOBAL15-56318
      root.$off('click:close-minicart', closeSideBarEvent);
    });
    return {
      cmsPagePath,
    };
  },
};
