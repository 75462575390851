// Workaround to set proper alternate link with whole path
// CMS returns only /explore but FE needs /explore/this-is-my-query
export const getExplorePageLocalizations = (
  path: string,
  localizations: any[] | undefined,
  locale: string
): any[] => {
  if (!path || !localizations || !localizations.length) {
    return [];
  }
  const agnosticPath = path.split(locale)[1];
  const exploreLocalizations = [];
  localizations.forEach((localization) => {
    const newLoc = { ...localization };
    newLoc.value.urlSegment = `/${localization.key}${agnosticPath}`;
    exploreLocalizations.push(newLoc);
  });
  return exploreLocalizations;
};
