

























































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { OrderHistoryTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'VfOrderCardSidebar',
  props: {
    translations: {
      type: Object as PropType<OrderHistoryTranslations>,
      default: () => ({}),
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    showCosts: {
      type: Boolean,
      default: false,
    },
    propertyClass: {
      type: String,
      default: '',
    },
    isReturns: {
      type: Boolean,
      default: false,
    },
    totalUnits: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    totalAmount() {
      return this.isReturns
        ? this.order.overallTotals.grandTotal
        : this.order.total_amount;
    },
    enforcedShippingFeeLabels() {
      return this.$t(
        `orderSummarySidebar.cart.enforcedShippingFee.${this.order.shipping_state}`
      );
    },
  },
});
