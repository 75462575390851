










































































import { defineComponent, useFetch, ref } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useHealthcheck } from '@vf/composables';

export default defineComponent({
  name: 'HealthCheck',
  layout: 'empty',
  props: {
    renderer: {
      type: String,
    },
  },
  setup(props) {
    const eapiHealth = ref([]);
    const cmsHealth = ref([]);

    const { root } = useRootInstance();
    const { getCmsHealth, getEapiHealth } = useHealthcheck(root);

    useFetch(() =>
      Promise.allSettled([
        (async () => {
          eapiHealth.value = await getEapiHealth(props.renderer);
        })(),
        (async () => {
          cmsHealth.value = await getCmsHealth(props.renderer);
        })(),
      ]).then(() => root.$log.debug('Healthcheck complete.'))
    );

    return {
      cmsHealth,
      eapiHealth,
    };
  },
});
