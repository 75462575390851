//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfMobileMenuColumn',
  inject: ['megaMenu'],
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    active() {
      return this.megaMenu.active;
    },
  },
  methods: {
    changeActive(title) {
      this.$emit('change-active', title);
      this.megaMenu.changeActive(title);
    },
  },
});
