//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'ForgotPassword',
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: 'Forgot Password',
    },
    emailLabel: {
      type: String,
      default: 'Email Address',
    },
    placeholder: {
      type: String,
      default: 'Type here',
    },
    validationMessages: {
      type: Object,
      default: () => ({
        requiredError: 'This field is required, please add your {{fieldName}}.',
        emailError: 'Please provide valid e-mail address.',
      }),
    },
    resetPasswordButton: {
      type: String,
      default: 'Reset Password',
    },
    cancelButton: {
      type: String,
      default: 'Cancel',
    },
    link: {
      type: String,
      default: '/account/sign-in',
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign-core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      email: null,
    };
  },
  computed: {
    inputClass() {
      return this.$themeConfig?.forgotPassword?.inputClass || '';
    },
  },
  methods: {
    getRequiredErrorMessage(fieldName) {
      return this.validationMessages.requiredError.replace(
        '{{fieldName}}',
        fieldName.toLowerCase()
      );
    },
    resetPassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.forgot_password.$el.querySelector('input').focus();
        return;
      }
      this.$emit('reset-password', {
        email: this.email,
        type: 'reset',
      });
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
});
