































































import { defineComponent, PropType } from '@vue/composition-api';
import { OrderProductsSummaryTranslations } from '@vf/api-contract';
import { ReturnDetailedItem } from '@vf/api-client';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfOrderProductSummary',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<OrderProductsSummaryTranslations>,
      required: true,
    },
    products: {
      type: Array,
      default: () => [],
    },
    productsKey: {
      type: String,
      default: '',
    },
    orderSummaryStatus: {
      type: String,
      default: '',
    },
    showGiftOption: {
      type: Boolean,
      default: false,
    },
    showItemPrice: {
      type: Boolean,
      default: false,
    },
    showSmallDescription: {
      type: Boolean,
      default: true,
    },
    showOnlyColorValue: {
      type: Boolean,
      default: false,
    },
    isReturns: {
      type: Boolean,
      default: false,
    },
    isReturned: {
      type: Boolean,
      default: false,
    },
    isCancelled: {
      type: Boolean,
      default: false,
    },
    returnStep: {
      type: String,
      default: 'details',
    },
    returnReason: {
      type: String,
      default: '',
    },
    showNotificationText: {
      type: Boolean,
      default: false,
    },
    isPricingStrikethroughEnabled: Boolean,
  },
  setup(_, context) {
    const { root } = context;
    const isSeparatorDisabled =
      root.$themeConfig?.orderProductSummary?.isSeparatorDisabled || false;

    const handleUpdateReturn = (payload: ReturnDetailedItem) => {
      context.emit('handle-update-return', payload);
    };

    return {
      isSeparatorDisabled,
      getOverrideImage: (product) =>
        product.imageDeclined ? root.$mediaUrlFallback() : null,
      handleUpdateReturn,
    };
  },
});
