












import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import { ComponentInstance, useCart } from '@vf/composables';

import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'Customizer',
  layout: 'cms/cmsDefault',
  setup() {
    const { root } = useRootInstance();
    const { addItem, setMiniCart } = useCart(root);
    const customizeQuery = computed(() => root.$route.query.customize);
    const customsFlowQuery = computed(() => root.$route.query.customsFlow);
    const randomizer = root.$route.query.customsFlow === 'Randomizer';
    const isFrameOpened = ref(false);
    let customsToolsMounted = ref(false);

    const rootOptions = root.$options as ComponentInstance;

    onMounted(() => {
      rootOptions.$eventBus.$on('addToCart', addToCart);
      rootOptions.$eventBus.$on(
        'closeCustomsIframe',
        closeCustomsVueComponentsIframe
      );
      rootOptions.$eventBus.$on('customsToolsMounted', postMessageToCustoms);
      rootOptions.$eventBus.$on('dataLayerPush', dataLayerPush);
      rootOptions.$eventBus.$on('customizerOpened', customizerOpened);
      rootOptions.$eventBus.$on(
        'styleSelectorMounted',
        setStyleSelectorMounted
      );
    });

    const setStyleSelectorMounted = () => {
      if (!isFrameOpened.value && !customsToolsMounted.value) {
        dispatchOpenIframe();
      }
    };

    const customizerOpened = (payload) => {
      const { body } = payload;
      if (body) {
        const params = body.params ? body.params : '';
        const recipe = body.recipe ? `&recipe=${body.recipe}` : '';
        const customizerRoute = `customizer.${body.model}.html?${recipe}${params}`;
        root.$router.push(customizerRoute);
      }
    };

    const addToCart = async (event) => {
      const { body: products } = event;
      try {
        await addItem(products, { isTemporary: false });

        root.$vfIframeCommunicator.postMessage({
          command: 'addToCart',
          body: {
            status: 'success',
            message: `Successfully added product(s) to cart`,
          },
        });
        if (root.$viewport?.isSmall) {
          // toggle header to display success tooltip only for mobile
          const headerContainer = document.querySelector(
            'div.vf-header__container'
          );
          const headerContainerTop = headerContainer.clientHeight;
          toggleHeader(headerContainerTop);
        }
        setMiniCart(true, root.$viewport?.isSmall && 6000);
      } catch (e) {
        root.$vfIframeCommunicator.postMessage({
          command: 'addToCart',
          body: {
            status: 'error',
            message: `Error in adding to cart with ${e}`,
          },
        });
      }
    };

    const postMessageToCustoms = () => {
      customsToolsMounted.value = true;
      if (!isFrameOpened.value) {
        dispatchOpenIframe();
      }
    };

    const dispatchOpenIframe = () => {
      if (root.$route.query.customize) {
        root.$vfIframeCommunicator.postMessage({
          command: 'styleSelectorIframeCallEvent',
          body: {
            id: 'getStarted',
            eventName: 'click',
          },
        });
        openCustomsVueComponentsIframe();
      }
    };

    const toggleHeader = (event) => {
      const top = event;
      const frame = document.querySelector('iframe[isCustomsIframe="true"]');
      frame.setAttribute('style', `top: ${top}px`);
      setTimeout(() => {
        frame.setAttribute('style', `top: 0`);
      }, 6000);
    };

    const dataLayerPush = (event) => {
      const { body } = event;
      root.$gtm.push(body.dataLayerData);
    };

    const closeCustomsVueComponentsIframe = () => {
      const frame = document.querySelector('iframe[isCustomsIframe="true"]');
      if (frame) {
        frame.classList.add('hidden');
        isFrameOpened.value = false;
      }
    };

    const openCustomsVueComponentsIframe = () => {
      const frame = document.querySelector('iframe[isCustomsIframe="true"]');
      if (frame) {
        frame.classList.remove('hidden');
        isFrameOpened.value = true;
      }
    };

    // for SSR reload for until CSR can be fixed
    watch([customizeQuery, customsFlowQuery], () => {
      root.$router.go(0);
    });

    onBeforeUnmount(() => {
      setMiniCart(false);
      rootOptions.$eventBus.$off(
        'closeCustomsIframe',
        closeCustomsVueComponentsIframe
      );
      rootOptions.$eventBus.$off('customizerOpened', customizerOpened);
      rootOptions.$eventBus.$off('addToCart', addToCart);
      rootOptions.$eventBus.$off('dataLayerPush', dataLayerPush);
    });

    return {
      randomizer,
      isFrameOpened,
    };
  },
});
