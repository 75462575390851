
































import { defineComponent } from '@nuxtjs/composition-api';
import { useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'I18nTranslations',
  layout: 'empty',
  props: {},
  setup() {
    const { root } = useRootInstance();
    const { staticTranslations } = useI18n(root);

    const areTranslationsLoaded =
      staticTranslations && Object.keys(staticTranslations).length > 0;

    return {
      areTranslationsLoaded,
      staticTranslations,
    };
  },
});
